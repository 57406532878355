
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F7fd55e8e-f990-47ef-805a-24ab5728f92c?alt=media&token=0610d935-1c98-448b-a45d-9eabc52a89fb"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Fed750dd9-0c35-4a44-b1dc-7b5476b5e219?alt=media&token=43be6e3c-a48d-4365-96a2-83d01929f666"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F99579fa4-2862-4e50-a34a-a4729e26d107?alt=media&token=84779239-66fb-4a5a-b7e5-1c2a53586bae"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Fd51d6ec0-8fe0-4275-9670-d0692a992a4a?alt=media&token=e6f72bec-e5df-4a21-9012-83ace00d211c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F5c1ff4e6-643b-413b-8b50-bbdbd36189b2?alt=media&token=8f671f30-dc47-463e-96d6-c3f4b59b0ef3"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Fc6b203df-e426-402a-abe8-6837d18c2124?alt=media&token=b0db4165-d9dc-4fa2-ab51-87a2b7ba8bb5"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F01539166-b2b0-4709-aed0-041f7b9b1c58?alt=media&token=a787146a-744f-40dd-a4ed-e0d33fa714b0"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F8c29d4cd-39e2-43eb-923a-b555aa729b17?alt=media&token=525985b0-0d2a-4121-958b-e26eb8d1cd6e"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F149ed875-7de1-42cf-8fc6-a1ff5bf4365a?alt=media&token=959e5cbc-029d-41e6-a183-115bc6315352"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Ff5ebfe6b-e396-45cc-8aeb-56d99e6d820e?alt=media&token=5ddab1ba-3157-4bb9-9946-afc526663e80"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F88a985e9-1e40-4050-9f15-91b304657032?alt=media&token=c41908c3-04b8-4ac1-a1ca-a08c7947bdf1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F0b51505c-938c-466a-8d52-b5672ebae32b?alt=media&token=7fd84e91-3aa7-4639-a4a8-abe7a5649ddc"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F29cc4569-3328-4b4b-b62c-0f9c612da88e?alt=media&token=c0a57bce-e482-42fd-af1e-b9d43608a547"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Ff663f36d-2934-4105-b903-a0e80b2f0c71?alt=media&token=4e5fd566-fb38-4e75-b9d3-981a500d685f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F42360fbc-d451-4785-8a95-66d03f1d3f2a?alt=media&token=bdd21768-f013-4733-9140-3333eb3ce68a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F8d5c1fc4-7704-430f-aa6c-af51e1929c42?alt=media&token=e9f9c4af-3071-48c2-a9bc-4f0b7d89cbc2"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F8505c47c-7e1a-4433-bd10-5bf53e8c2de3?alt=media&token=441e54ae-2300-4ecd-aa00-120d20ab52c7"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F00d48c4e-148c-47b3-b23d-4f79bedba4ac?alt=media&token=5161e8ce-a758-46f4-8b3d-7cb046077f42"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2F32d3a914-f897-4fba-b304-a2f00b16e1e4?alt=media&token=f31e97ca-f892-4590-8a9f-0e46f123ad25"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2Fe9e50419-e88c-4a91-becb-24326918aa4f?alt=media&token=227f1aec-7b11-4414-8e2d-be1bf410b556"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU6ES%2FDIVU6ESMAT.pdf?alt=media&token=d7ac2540-1658-495c-b381-446b35c5d8c6' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
